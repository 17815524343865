import { createSelector } from "reselect";

export const getAllAlbums = (state) => state.albums.allAlbums;
export const getAlbums = (state) => state.albums.albums;
export const getChooseYourColor = (state) => state.albums.chooseYourColor;
export const getPodcasts = (state) => state.albums.podcasts;
export const getSpclnchRecommendsAlbums = (state) =>
  state.albums.spclnchRecommends;
export const getComponents = (state) => state.albums.components;


export const getDescription = (state) => state.albums.description;

const getParam = (_state, param) => param;

export const getAlbum = createSelector([getAlbums, getParam], (albums, id) => {
  return albums.find((album) => album.id === id);
});

export const getPodcast = createSelector(
  [getPodcasts, getParam],
  (albums, id) => {
    return albums.find((album) => album.id === id);
  }
);

export const getComponent = createSelector(
    [getComponents, getParam],
    (albums, id) => {
      return albums.find((album) => album.id === id);
    }
);

export const getColor = createSelector(
  [getChooseYourColor, getParam],
  (albums, id) => {
    return albums.find((album) => album.id === id);
  }
);

export const getSpclnchRecommendsAlbum = createSelector(
  [getSpclnchRecommendsAlbums, getParam],
  (albums, id) => {
    return albums.find((album) => album.id === id);
  }
);

export const sortedArtistDiscography = createSelector(
  [getAllAlbums, getParam],
  (albums, { artist, albumName }) => {
    return albums.filter(
      ({ artist: _artist, remix, albumName: _albumName }) =>
        albumName !== _albumName &&
        (artist === _artist || remix.includes(artist))
    );
  }
);
