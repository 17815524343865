export const SOCIAL_TITLES = {
  bandcamp: "Bandcamp",
  spotify: "Spotify",
  appleMusic: "Apple Music",
  beatport: "Beatport",
  deejayDe: "deejay.de",
  junoRecords: "Juno Records",
  soundcloud: "Soundcloud",
  youtube: "YouTube",
  mixcloud: "Mixcloud",
  flatAudio: "flat.audio",
  buyDownload: "Buy / download",
};
