import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { isMobile } from 'react-device-detect';

import { AlbumInfo } from '../../components/AlbumInfo';
import { Discography } from '../../containers/Discography';
import { getColor } from '../../store/selectors/albums';
import { AlbumInfoMobile } from '../../components/AlbumInfoMobile';

export function ColorScreen() {
  const { id } = useParams();
  const color = useSelector((state) => getColor(state, id));

  return (
    <>
      {isMobile ? (
        <AlbumInfoMobile album={color} />
      ) : (
        <AlbumInfo album={color} />
      )}
      <Discography album={color} />
    </>
  );
}
