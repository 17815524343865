import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store;
let persistor;

export function getStore() {
  return { store, persistor };
}

function init() {
  store = createStore(
    persistedReducer,
    composeEnhancer(applyMiddleware(thunk))
  );
  persistor = persistStore(store);

  return getStore();
}

export const Store = {
  init,
  getStore,
};
