import React from 'react';
import ContentLoader from 'react-content-loader';

const PlayerLoader = (props) => (
  <ContentLoader
    speed={5}
    width={454}
    height={42}
    viewBox="0 0 454 42"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="454" height="42" />
  </ContentLoader>
);

export default PlayerLoader;
