import { createAction } from "redux-actions";

import {
  SET_ALBUMS,
  SET_ALL_ALBUMS,
  SET_CHOOSE_YOUR_COLOR,
  SET_PODCASTS,
    SET_COMPONENTS,
  SET_SPCLNCH_RECOMMENDS,
  SET_DESCRIPTION,
} from "../reducers/albums";

const setAllAlbums = createAction(SET_ALL_ALBUMS);
const setAlbums = createAction(SET_ALBUMS);
const setChooseYourColor = createAction(SET_CHOOSE_YOUR_COLOR);
const setPodcasts = createAction(SET_PODCASTS);
const setComponents = createAction(SET_COMPONENTS);
const setSpclnchRecommends = createAction(SET_SPCLNCH_RECOMMENDS);

const setDescription = createAction(SET_DESCRIPTION);

export function loadData() {
  return function (dispatch) {
    return fetch(
      // "https://raw.githubusercontent.com/pavelokun/spclnch-test/main/allAlbums.json"
      "https://raw.githubusercontent.com/pavelokun/spclnch-data/main/allAlbums.json"
    )
      .then((response) => response.json())
      .then((data) => {
        const sortedAllAlbums = [
          ...data.albums,
          ...data.chooseYourColor,
          ...data.podcasts,
          ...data.components,
          ...data.spclnchRecommends,
        ].sort((a, b) => {
          a = new Date(a.date).getTime();
          b = new Date(b.date).getTime();

          if (b > a) {
            return 1;
          } else if (b < a) {
            return -1;
          } else {
            return 0;
          }
        });
        dispatch(setAllAlbums(sortedAllAlbums));
        dispatch(setAlbums(data.albums));
        dispatch(setPodcasts(data.podcasts));
        dispatch(setComponents(data.components));
        dispatch(setChooseYourColor(data.chooseYourColor));
        dispatch(setSpclnchRecommends(data.spclnchRecommends));
        dispatch(setDescription(data.description));
      })
      .catch((err) => console.log("Can't load data.json: ", err));
  };
}
