import React from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import styles from "./index.module.css";

function AlbumCardComponent({ artist, albumName, imageId, type, id }) {
  return (
    <Link
      to={`/${type === 'spclnch-recommends' ? 'premieres' : type}/${id}`}
      className={isMobile ? styles.linkMobile : styles.link}
    >
      <div
        className={
          isMobile ? styles.albumContainerMobile : styles.albumContainer
        }
      >
        <img
          // src={`https://raw.githubusercontent.com/pavelokun/spclnch-test/main/small/${type}/${imageId}`}

          src={`https://raw.githubusercontent.com/pavelokun/spclnch-data/main/small/${type}/${imageId}`}
          className={isMobile ? styles.albumMobile : styles.album}
          alt={`${artist} - ${albumName}`}
        />
      </div>
      <p className={styles.artist}>{artist}</p>
      <p className={styles.albumName}>{albumName}</p>
    </Link>
  );
}

export const AlbumCard = React.memo(AlbumCardComponent);
