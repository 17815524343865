import React from "react";
import { Redirect } from "react-router-dom";
import { AlbumScreen } from "./album";
import { ColorScreen } from "./color";
import { ContactScreen } from "./contact";
import { HomeScreen } from "./home";
import { PodcastScreen } from "./podcast";
import { SpclnchRecommendsAlbumScreen } from "./spclnchRecommendsAlbum/spclnchRecommendsAlbum";
import {ComponentScreen} from "./component";

export const routes = [
  {
    path: "/",
    component: HomeScreen,
    exact: true,
  },
  {
    path: "/albums",
    component: HomeScreen,
    exact: true,
  },
  {
    path: "/podcasts",
    component: HomeScreen,
    exact: true,
  },
  {
    path: "/components",
    component: HomeScreen,
    exact: true,
  },
  {
    path: "/choose-your-color",
    component: HomeScreen,
    exact: true,
  },
  {
    path: "/albums/:id",
    component: AlbumScreen,
  },
  {
    path: "/choose-your-color/:id",
    component: ColorScreen,
  },
  {
    path: "/podcasts/:id",
    component: PodcastScreen,
  },
  {
    path: "/components/:id",
    component: ComponentScreen,
  },
  {
    path: "/about-contact",
    component: ContactScreen,
  },
  {
    path: "/premieres",
    component: HomeScreen,
    exact: true,
  },
  {
    path: "/premieres/:id",
    component: SpclnchRecommendsAlbumScreen,
  },
  {
    path: "*",
    component: () => <Redirect to="/" />,
  },
];
