import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { MobileHeader } from '../MobileHeader';

function MobileLayoutComponent({ children }) {
  return (
    <div className={styles.layout}>
      <MobileHeader />
      <main className={styles.main}>
        <div className={styles.empty} />
        {children}
      </main>
    </div>
  );
}

MobileLayoutComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const MobileLayout = React.memo(MobileLayoutComponent);
