import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter,
  Switch,
  Route,
  withRouter,
  useLocation,
} from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Layout } from '../containers/Layout';
import { MobileLayout } from '../containers/MobileLayout';

import { loadData } from '../store/actions/albums';
import { routes } from './routes';

function RouteWithSubRoutes(route) {
  return (
    <Route
      exact={route.exact || false}
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

export function Screens() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadData());
  });
  const LayoutComponent = isMobile ? MobileLayout : Layout;
  return (
    <BrowserRouter>
      <ScrollToTop>
        <LayoutComponent>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </LayoutComponent>
      </ScrollToTop>
    </BrowserRouter>
  );
}
