import React, { useState } from 'react';
import PlayerLoader from '../PlayerLoader';
import styles from './index.module.css';
export function SoundcloudPlayer({ src }) {
  const [isPlayerLoaded, setIsPlayerLoaded] = useState(false);
  const onLoad = () => setIsPlayerLoaded(true);
  return (
    <div style={{ position: 'relative', overflow: 'hidden', display: 'flex' }}>
      {!isPlayerLoaded && (
        <div style={{ position: 'absolute' }}>
          <PlayerLoader />
        </div>
      )}
      <div className={styles.container}>
        <iframe
          width="100%"
          height="20"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src={src}
          title={src}
          onLoad={onLoad}
        ></iframe>
      </div>
    </div>
  );
}
