import { handleActions } from "redux-actions";
export const SET_ALL_ALBUMS = "ALBUMS/SET_ALL_ALBUMS";

export const SET_ALBUMS = "ALBUMS/SET_ALBUMS";
export const SET_CHOOSE_YOUR_COLOR = "ALBUMS/SET_CHOOSE_YOUR_COLOR";
export const SET_PODCASTS = "ALBUMS/SET_PODCASTS";
export const SET_COMPONENTS = "ALBUMS/SET_COMPONENTS";
export const SET_DESCRIPTION = "ALBUMS/SET_DESCRIPTION";
export const SET_SPCLNCH_RECOMMENDS = "ALBUMS/SET_SPCLNCH_RECOMMENDS";

const initialState = {
  allAlbums: [],
  albums: [],
  chooseYourColor: [],
  podcasts: [],
  components: [],
  spclnchRecommends: [],
  description: [],
};

function getReducerSetter(prop) {
  return (state, { payload }) => ({ ...state, [prop]: payload });
}

export default handleActions(
  {
    [SET_ALL_ALBUMS]: getReducerSetter("allAlbums"),
    [SET_ALBUMS]: getReducerSetter("albums"),
    [SET_CHOOSE_YOUR_COLOR]: getReducerSetter("chooseYourColor"),
    [SET_PODCASTS]: getReducerSetter("podcasts"),
    [SET_COMPONENTS]: getReducerSetter("components"),
    [SET_SPCLNCH_RECOMMENDS]: getReducerSetter("spclnchRecommends"),
    [SET_DESCRIPTION]: getReducerSetter("description"),
  },
  initialState
);
