import { GlobalStore } from './components/GlobalStore';
import { Screens } from './screens';

import { Store } from './store';

const { store, persistor } = Store.init();
export function App() {
  return (
    <GlobalStore store={store} persistor={persistor}>
      <Screens />
    </GlobalStore>
  );
}
