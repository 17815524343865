import { NavLink } from "react-router-dom";
import IllustrationImage from "../../assets/images/illustration.svg";

import styles from "./index.module.css";

export function Header() {
  const activeStyle = {
    color: "black",
    fontWeight: 500,
    textDecoration: "underline",
  };
  return (
    <header className={styles.header}>
      <div className={styles.fixedWrapper}>
        <nav className={styles.nav1}>
          <ul className={styles.list}>
            {/*{categories.map(({route, headerTitle}) => {*/}
            {/*  const exact = route === '/'*/}
            {/*  return <li>*/}
            {/*    <NavLink to={route} exact={exact} activeStyle={activeStyle}>*/}
            {/*      {headerTitle}*/}
            {/*    </NavLink>*/}
            {/*  </li>*/}
            {/*})}*/}
            <li>
              <NavLink to="/" exact activeStyle={activeStyle}>
                LATEST NEWS
              </NavLink>
            </li>
            <li>
              <NavLink to="/albums" activeStyle={activeStyle}>
                ALBUMS
              </NavLink>
            </li>
            <li>
              <NavLink to="/podcasts" activeStyle={activeStyle}>
                PODCASTS
              </NavLink>
            </li>
            <li>
              <NavLink to="/components" activeStyle={activeStyle}>
                COMPONENTS
              </NavLink>
            </li>
            <li>
              <NavLink to="/choose-your-color" activeStyle={activeStyle}>
                CHOOSE YOUR COLOR
              </NavLink>
            </li>
            <li>
              <NavLink to="/premieres" activeStyle={activeStyle}>
                PREMIERES
              </NavLink>
            </li>
            <li>
              <NavLink to="/about-contact" activeStyle={activeStyle}>
                ABOUT & CONTACT
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <div className={styles.illustration}>
        <img src={IllustrationImage} alt="illustration" />
      </div>
    </header>
  );
}
