import React from "react";
import parse from "html-react-parser";

import styles from "./index.module.css";
import { BandcampPlayer } from "../BandcampPlayer";
import { SoundcloudPlayer } from "../SoundcloudPlayer";
import { SOCIAL_TITLES } from "../../constants";

function renderTracklist(embed) {
  return (
    <div className={styles.tracklist}>
      {embed.map((item) => {
        if (item.href) {
          return <BandcampPlayer key={item.src} {...item} />;
        }
        return <SoundcloudPlayer key={item.src} {...item} />;
      })}
    </div>
  );
}

function Title({ style, artist, albumName }) {
  return (
    <div className={style}>
      <h2 className={styles.h2}>{artist}</h2>
      <h3 className={styles.h3}>{albumName}</h3>
    </div>
  );
}
function format(date) {
  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  const day = date.substring(8, 10);
  return month + "-" + day + "-" + year;
}
export function AlbumInfoMobile({ album }) {
  const {
    artist,
    albumName,
    date,
    catalogue,
    description,
    type,
    imageId,
    embed,
    social: {
      bandcamp,
      spotify,
      appleMusic,
      beatport,
      deejayDe,
      junoRecords,
      soundcloud,
      youtube,
      mixcloud,
      "flat.audio": flatAudio,
      buyDownload,
    },
  } = album;
  const releaseDate = format(date);
  return (
    <div className={styles.albumInfo}>
      <img
        // src={`https://raw.githubusercontent.com/pavelokun/spclnch-test/main/medium/${type}/${imageId}`}
        src={`https://raw.githubusercontent.com/pavelokun/spclnch-data/main/medium/${type}/${imageId}`}
        className={styles.albumImage}
        alt=""
      />
      <Title style={styles.leftTitle} artist={artist} albumName={albumName} />
      <div className={styles.subtitle}>
        <p>Release date: {releaseDate}</p>
        {type === "albums" && (
          <p className={styles.catalogue}>Catalogue: {catalogue}</p>
        )}
      </div>
      <article className={styles.description}>{parse(description)}</article>
      <div className={styles.social}>
        <ul>
          {bandcamp !== "null" && (
            <li>
              <a href={bandcamp} target="_blank" rel="noopener noreferrer">
                {SOCIAL_TITLES.bandcamp}
              </a>
            </li>
          )}
          {spotify !== "null" && (
            <li>
              <a href={spotify} target="_blank" rel="noopener noreferrer">
                {SOCIAL_TITLES.spotify}
              </a>
            </li>
          )}
          {appleMusic !== "null" && (
            <li>
              <a href={appleMusic} target="_blank" rel="noopener noreferrer">
                {SOCIAL_TITLES.appleMusic}
              </a>
            </li>
          )}
          {beatport !== "null" && (
            <li>
              <a href={beatport} target="_blank" rel="noopener noreferrer">
                {SOCIAL_TITLES.beatport}
              </a>
            </li>
          )}
          {deejayDe !== "null" && (
            <li>
              <a href={deejayDe} target="_blank" rel="noopener noreferrer">
                {SOCIAL_TITLES.deejayDe}
              </a>
            </li>
          )}
          {junoRecords !== "null" && (
            <li>
              <a href={junoRecords} target="_blank" rel="noopener noreferrer">
                {SOCIAL_TITLES.junoRecords}
              </a>
            </li>
          )}
          {soundcloud !== "null" && (
            <li>
              <a href={soundcloud} target="_blank" rel="noopener noreferrer">
                {SOCIAL_TITLES.soundcloud}
              </a>
            </li>
          )}
          {youtube !== "null" && (
            <li>
              <a href={youtube} target="_blank" rel="noopener noreferrer">
                {SOCIAL_TITLES.youtube}
              </a>
            </li>
          )}
          {mixcloud !== "null" && (
            <li>
              <a href={mixcloud} target="_blank" rel="noopener noreferrer">
                {SOCIAL_TITLES.mixcloud}
              </a>
            </li>
          )}
          {flatAudio !== "null" && (
            <li>
              <a href={flatAudio} target="_blank" rel="noopener noreferrer">
                {SOCIAL_TITLES.flatAudio}
              </a>
            </li>
          )}
          {buyDownload !== "null" && (
            <li>
              <a href={buyDownload} target="_blank" rel="noopener noreferrer">
                {SOCIAL_TITLES.buyDownload}
              </a>
            </li>
          )}
        </ul>
      </div>
      {renderTracklist(embed, artist, albumName)}
    </div>
  );
}
