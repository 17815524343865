import React, { useState } from 'react';
import PlayerLoader from '../PlayerLoader';
import styles from './index.module.css';
export function BandcampPlayer({ src }) {
  const [isPlayerLoaded, setIsPlayerLoaded] = useState(false);
  const onLoad = () => setIsPlayerLoaded(true);

  return (
    <div className={styles.playerContainer}>
      {!isPlayerLoaded && (
        <div className={styles.loaderContainer}>
          <PlayerLoader />
        </div>
      )}
      <iframe
        className={styles.iframe}
        src={src}
        seamless
        title={src}
        onLoad={onLoad}
      ></iframe>
    </div>
  );
}
