import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { isMobile } from 'react-device-detect';

import { AlbumInfo } from '../../components/AlbumInfo';
import { Discography } from '../../containers/Discography';
import { getPodcast } from '../../store/selectors/albums';
import { AlbumInfoMobile } from '../../components/AlbumInfoMobile';

export function PodcastScreen() {
  const { id } = useParams();
  const podcast = useSelector((state) => getPodcast(state, id));

  return (
    <>
      {isMobile ? (
        <AlbumInfoMobile album={podcast} />
      ) : (
        <AlbumInfo album={podcast} />
      )}
      <Discography album={podcast} />
    </>
  );
}
