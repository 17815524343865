import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import disableScroll from "disable-scroll";

import LogoIcon from "../../assets/images/logo.png";

import BandcampIcon from "../../assets/images/bandcamp.svg";
import InstagramIcon from "../../assets/images/instagram.svg";
import FacebookIcon from "../../assets/images/fb.svg";
import SoundcloudIcon from "../../assets/images/soundcloud.svg";
import YoutubeIcon from "../../assets/images/youtube.svg";
import IllustrationImage from "../../assets/images/illustration-mobile.svg";

import styles from "./index.module.css";

export function MobileHeader() {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [layoutStyle, setLayoutStyle] = useState({});
  const onMenuPress = () => setIsMenuOpened(!isMenuOpened);
  const closeMenu = () => {
    disableScroll.off();
    setIsMenuOpened(false);
  };
  useEffect(() => {
    if (isMenuOpened) {
      disableScroll.on();
      setTimeout(() => {
        setLayoutStyle(styles.openedClickableContainer);
      }, 200);
    } else {
      disableScroll.off();
      setTimeout(() => {
        setLayoutStyle(styles.closedClickableContainer);
      }, 200);
    }
  }, [isMenuOpened]);
  const activeStyle = {
    color: "black",
    fontWeight: 500,
    textDecoration: "underline",
  };
  return (
    <>
      <header className={styles.header}>
        <h1 className={styles.h1}>
          <a href="/">
            <img className={styles.logo} src={LogoIcon} alt="spclnch" />
          </a>
        </h1>
        <button className={styles.menuButton} onClick={onMenuPress}>
          <div
            className={
              isMenuOpened ? styles.logoLineMiddle : styles.logoLineTop
            }
          ></div>
          <div className={styles.logoLineMiddle}></div>
          <div
            className={
              isMenuOpened ? styles.logoLineMiddle : styles.logoLineBottom
            }
          ></div>
        </button>
      </header>
      <div className={isMenuOpened ? styles.openedMenu : styles.closedMenu}>
        <div className={styles.menuContainer}>
          <nav className={styles.nav1}>
            <ul className={styles.list}>
              <li>
                <NavLink
                  to="/"
                  exact
                  activeStyle={activeStyle}
                  onClick={closeMenu}
                >
                  LATEST NEWS
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/albums"
                  activeStyle={activeStyle}
                  onClick={closeMenu}
                >
                  ALBUMS
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/podcasts"
                  activeStyle={activeStyle}
                  onClick={closeMenu}
                >
                  PODCASTS
                </NavLink>
              </li>
              <li>
                <NavLink to="/components" activeStyle={activeStyle} onClick={closeMenu}>
                  COMPONENTS
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/choose-your-color"
                  activeStyle={activeStyle}
                  onClick={closeMenu}
                >
                  CHOOSE YOUR COLOR
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/premieres"
                  activeStyle={activeStyle}
                  onClick={closeMenu}
                >
                  PREMIERES
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about-contact"
                  activeStyle={activeStyle}
                  onClick={closeMenu}
                >
                  ABOUT & CONTACT
                </NavLink>
              </li>
            </ul>
          </nav>
          <nav className={styles.nav2}>
            <ul className={styles.linkList}>
              <li>
                <a
                  href="https://spclnch.bandcamp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={BandcampIcon} alt="bandcamp" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/spclnch/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={InstagramIcon} alt="instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/spclnch"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={FacebookIcon} alt="facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://soundcloud.com/spclnch"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={SoundcloudIcon} alt="soundcloud" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCG3ktrs1qhFKLI76vC9tAqQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={YoutubeIcon} alt="youtube" />
                </a>
              </li>
            </ul>
          </nav>
          <div className={styles.illustration}>
            <img src={IllustrationImage} alt="illustration" />
          </div>
        </div>

        {isMenuOpened && (
          <div className={layoutStyle} onClick={onMenuPress}></div>
        )}
      </div>
    </>
  );
}
