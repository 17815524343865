import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import albums from './albums';

const albumsPersistConfig = {
  key: 'albums',
  storage: storage,
};

export default combineReducers({
  albums: persistReducer(albumsPersistConfig, albums),
});
