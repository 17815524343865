import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { isMobile } from "react-device-detect";

import { AlbumInfo } from "../../components/AlbumInfo";
import { Discography } from "../../containers/Discography";
import { getSpclnchRecommendsAlbum } from "../../store/selectors/albums";
import { AlbumInfoMobile } from "../../components/AlbumInfoMobile";

export function SpclnchRecommendsAlbumScreen() {
  const { id } = useParams();
  const album = useSelector((state) => getSpclnchRecommendsAlbum(state, id));

  return (
    <>
      {isMobile ? (
        <AlbumInfoMobile album={album} />
      ) : (
        <AlbumInfo album={album} />
      )}
      <Discography album={album} />
    </>
  );
}
