import React from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { getDescription } from "../../store/selectors/albums";
import parse from "html-react-parser";

import styles from "./index.module.css";

export function ContactScreen() {
  const description = useSelector(getDescription);
  return (
    <div className={isMobile ? styles.contactMobile : styles.contactScreen}>
      <div className={isMobile ? {} : styles.section}>
        {description.map(({ title, article }) => {
          return (
            <section key={title || Math.random()}>
              {title && <h4 className={styles.title}>{title}</h4>}
              {article && (
                <article className={styles.article}>{parse(article)}</article>
              )}
            </section>
          );
        })}
      </div>
    </div>
  );
}
