import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { Header } from '../Header';
import LogoIcon from '../../assets/images/logo.png';
import BandcampIcon from '../../assets/images/bandcamp.svg';
import InstagramIcon from '../../assets/images/instagram.svg';
import FacebookIcon from '../../assets/images/fb.svg';
import SoundcloudIcon from '../../assets/images/soundcloud.svg';
import YoutubeIcon from '../../assets/images/youtube.svg';

function LayoutComponent({ children }) {
  const { pathname } = useLocation();

  return (
    <>
      <div className={styles.layout}>
        <div className={styles.headerContainer}>
          <div
            className={
              pathname.match(/\//g).length > 1 ? styles.header2 : styles.header
            }
          >
            <h1>
              <a href="/">
                <img src={LogoIcon} alt="spclnch" className={styles.logo} />
              </a>
            </h1>
            <nav className={styles.nav2}>
              <ul className={styles.linkList}>
                <li>
                  <a
                    href="https://spclnch.bandcamp.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={BandcampIcon} alt="bandcamp" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/spclnch/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={InstagramIcon} alt="instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/spclnch"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={FacebookIcon} alt="facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://soundcloud.com/spclnch"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={SoundcloudIcon} alt="soundcloud" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCG3ktrs1qhFKLI76vC9tAqQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={YoutubeIcon} alt="youtube" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <Header />
        <main className={styles.main}>
          <div className={styles.empty} />
          {children}
          <div className={styles.emptyBottom} />
        </main>
      </div>
    </>
  );
}

LayoutComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const Layout = React.memo(LayoutComponent);
