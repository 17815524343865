import React from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import { AlbumCard } from "../../components/AlbumCard";

import {
  getAlbums,
  getAllAlbums,
  getChooseYourColor,
  getPodcasts,
  getComponents,
  getSpclnchRecommendsAlbums,
} from "../../store/selectors/albums";

import styles from "./index.module.css";
import { useLocation } from "react-router-dom";

const selector = (pathname) => {
  switch (pathname) {
    case "/home":
      return getAllAlbums;
    case "/albums":
      return getAlbums;
    case "/choose-your-color":
      return getChooseYourColor;
    case "/podcasts":
      return getPodcasts;
    case "/components":
      return getComponents;
    case "/premieres":
      return getSpclnchRecommendsAlbums;
    default:
      return getAllAlbums;
  }
};

function HomeScreenComponent() {
  const { pathname } = useLocation();
  const albums = useSelector(selector(pathname));
  return (
    <div className={isMobile ? styles.homeMobile : styles.homeScreen}>
      {albums?.map((item) => (
        <AlbumCard key={item.albumName} {...item} />
      ))}
    </div>
  );
}

export const HomeScreen = React.memo(HomeScreenComponent);
