import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { AlbumCard } from '../../components/AlbumCard';
import { sortedArtistDiscography } from '../../store/selectors/albums';

import styles from './index.module.css';

export function Discography({ album }) {
  const { artist } = album;

  const discography = useSelector((state) =>
    sortedArtistDiscography(state, album)
  );
  return (
    <>
      {!!discography.length && (
        <section
          className={
            isMobile
              ? styles.discographyContainerMobile
              : styles.discographyContainer
          }
        >
          <h4
            className={
              isMobile ? styles.discographyTitleMobile : styles.discographyTitle
            }
          >
            More from {artist}
          </h4>
          <div
            className={isMobile ? styles.discographyMobile : styles.discography}
          >
            {discography.map((item) => (
              <AlbumCard key={item.albumName} {...item} />
            ))}
          </div>
        </section>
      )}
    </>
  );
}
